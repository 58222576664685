<!--original code-->
<!-- <div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" class="img-fluid lazy-loading"
          alt="{{ product.images[0].alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading"
          alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
        <h6>{{ product?.title | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money">
            {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
      <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul>
    </div>
  </div>
</div> -->

<!--Modified code-->
<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">

      <!-- <span class="lable3" *ngIf="productkart.new">new</span>
      <span class="lable4" *ngIf="productkart.sale">on sale</span> -->
    </div>
    <div class="front">


      <a (click)="GoToDetailNEW(productkart.rowID,productkart?.setType);">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : ProductImage+productkart.productID+'/frontImage/'+productkart.frontImage"
          class="img-fluid lazy-loading" alt="" />
      </a>


      <!-- <a (click)="GoToDetail(productkart.rowID,productkart?.productSizeId,productkart?.setType,productkart?.setNo);">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : ProductImage+productkart.productID+'/frontImage/'+productkart.frontImage"
          class="img-fluid lazy-loading" alt="" />
      </a> commented on 15/01/2023 -->

      <!-- <a (click)="GoToDetail(productkart.rowID,productkart?.productSizeId,productkart?.setType,productkart?.setNo);">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : ProductImage+productkart?.productID+'/productColorImage/'+productkart?.productSizeColorId+'/'+productkart?.productSizeColorImg"
          class="img-fluid lazy-loading" alt="" />
      </a> -->
    </div>
    <!-- <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', productkart.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : productkart.images[1].src" class="img-fluid lazy-loading"
          alt="{{ productkart.images[1].alt }}">
      </a>
    </div> -->
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of productkart.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul> -->
    <div style="position: absolute;
        display: inline-block;
        top: 12px;
        right: 12px;   
        cursor: pointer;">
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(productkart)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(productkart)" *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </a> -->
      <a href="javascript:void(0)" title="Add to Wishlist" *ngIf="!productkart.isWishList"
        (click)="addToWishlist(productkart)">
        <i class="ti-heart" aria-hidden="true" style="color: #999;
        font-size: 1.00rem;"> </i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" *ngIf="productkart.isWishList"
        (click)="addToWishlist(productkart)">
        <i class="fa fa-heart" aria-hidden="true" style="color: red;
        font-size: 1.00rem;"> </i>
      </a>
      <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a> -->
      <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(productkart)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a> -->
    </div>
  </div>
  <div class="product-detail"
    (click)="GoToDetail(productkart.rowID,productkart?.productSizeId,productkart?.setType,productkart?.setNo);">
    <div class="box-design">
      <!-- <bar-rating [rate]="productkart?.averageRating" theme="fontawesome-o" [readOnly]="true"></bar-rating> -->
      <h5 class="mb-4">
        <span class="review-box" *ngIf="productkart?.totalRating>0"> {{(productkart?.averageRating)}}
          ★</span>&nbsp;
        <span class="text-grey" *ngIf="productkart?.totalRating>0">({{productkart?.totalRating}})</span>
      </h5>
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]"> -->
      <a href="javaScript:void(0);">
        <h6 *ngIf="(productkart?.productName).length>55" title="{{productkart?.productName}}">
          {{ (productkart?.productName).substr(0, 55) | titlecase }}...</h6>
        <h6 *ngIf="(productkart?.productName).length<=55" title="{{productkart?.productName}}">
          {{ productkart?.productName | titlecase }}</h6>
      </a>

      <!-- <h4 *ngIf="user!=null && user[0]?.isPersonal==true && productkart?.salePrice>0">
        {{ (productkart?.salePrice) | currency:currency?.currency:'symbol' }}
      </h4>
      <h4 *ngIf="user!=null && user[0]?.isBusiness==true && productkart?.businessPrice>0">
        {{ (productkart?.businessPrice) | currency:currency?.currency:'symbol' }}
      </h4> -->


      <!-- <label for="Compare"> Add to Compare
      </label> -->
      <!-- <h4 *ngIf="productkart?.setNo==0">
                {{ productkart?.salePrice | currency:currency?.currency:'symbol' }}
                <del *ngIf="productkart?.discount > 0"> <span class="money">
            {{ productkart?.price  | currency:currency?.currency:'symbol' }}</span></del>
                <br />
                <div *ngIf="productkart?.discount > 0">{{(productkart?.discount)}}% off
                </div>

            </h4> -->
      <!-- <h4 *ngIf=" productkart?.setNo>0">
                {{ productkart?.salePrice/productkart?.piece | currency:currency?.currency:'symbol' }}
                <del *ngIf="productkart?.discount > 0"> <span class="money">
            {{ productkart?.price/productkart?.piece  | currency:currency?.currency:'symbol' }}</span></del>
                <br />
                <div *ngIf="productkart?.discount > 0">
                    {{(((productkart?.price-productkart?.salePrice)*100)/productkart?.price).toFixed(2)}}% off
                </div>

            </h4> -->
      <!-- <ul class="color-variant" *ngIf="Color(productkart?.productSizeColor).length">
        <li [class]="color" *ngFor="let color of Color(productkart?.productSizeColor)"
          [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, productkart?.productSizeColor)">

        </li>

      </ul> -->

      <!-- <ul *ngIf="Size(productkart?.productSizeColor).length">
        <li
          style="cursor: pointer;color: #222; font-size: 12px;padding: 4px;align-items: center; justify-content: center;height: 100%"
          *ngFor="let size of Size(productkart?.productSizeColor)"
          (click)="ChangeVariants(size, productkart?.productSizeColor)">
          {{size}}
        </li>
      </ul> -->

    </div>
  </div>
  <!-- <span class="only-show-on-hover">
    <label style="color: #3c5edb;">
      <input type="checkbox" (change)="addToCompare(productkart)" value="">&nbsp;
      Add to Compare</label>
  </span> -->


</div>
<!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box> -->
<!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view> -->
<!-- <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->