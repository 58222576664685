import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { CategoryService } from 'src/app/Service/category.service';
//import { SubCategoryService } from 'src/app/Service/Subcategory.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  lstCategory: any[];

  constructor(public productService: ProductService,
    private _catService: CategoryService) {
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.BindCategory();
  }

  //commented on 08/07/2020

  // get filterbyCategory() {
  //   const category = [...new Set(this.products.map(product => product.type))]
  //   return category
  // }

  BindCategory() {

    let catObj = {
      MainCategoryID: 1,
      Active: true
    }

    this._catService.GetCategory(catObj).subscribe(res => {
      this.lstCategory = res;
    });
  }

}
