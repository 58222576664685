import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
//import { ElementsComponent } from './elements/elements.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import 'hammerjs';
import 'mousetrap';
import { DatePipe } from '@angular/common';
import { AuthInterceptor } from './auth/auth.interceptor';
import { OrderInvoiceComponent } from './Report/order-invoice/order-invoice.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
//import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// Import the library
//import { NgxImageZoomModule } from 'ngx-image-zoom';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    OrderInvoiceComponent,
    //ElementsComponent
  ],
  imports: [
    // NgxGoogleAnalyticsModule.forRoot('G-KJJDMZ6X57'),
    // NgxGoogleAnalyticsRouterModule,

    NgxSpinnerModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),

    BrowserAnimationsModule,
    HttpClientModule,
    //NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    //NgxImageZoomModule, // <-- Add this line
  ],
  providers: [DatePipe,
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
