<!-- .block-sub-category-carousel -->

<div class="theme-card mb-5">
  <h5 class="title-border">{{ title }}</h5>

  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container *ngFor="let subCat of lstSubcategory | slice : 0 : 100">
      <ng-template carouselSlide>

        <div class="box-design">
          <a href="javaScript:void(0);" (click)="GoToShopCollection(subCat.path)" style="color:#777">
                  <img style="margin: 0 auto;"
                    [defaultImage]="
                      ImageSrc
                        ? ImageSrc
                        : 'assets/images/product/placeholder.jpg'
                    "
                    [lazyLoad]="
                      ImageSrc ? ImageSrc : SubCategoryImage + subCat.imagePath
                    "
                    class="img-fluid w-auto mb-2"
                    alt=""
                  />
                  <div class="text-center" >
                    {{ (subCat?.title).substr(0, 30) | titlecase }}...
                    <!-- {{ (subCat?.name).substr(0, 30) | titlecase }}... -->
                  </div>
                </a>
        </div>

        <!-- <div class="media"  style="border: 1px solid red ;">
                        <a  href="javaScript:void(0);" (click)="GoToShopCollection(subCat.catCode, subCat.subCatCode);">
                            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : SubCategoryImage+subCat.imagePath" class="img-fluid w-auto" alt="" />
                        </a>
                        <div class="media-body align-self-center" >
                            <a href="javaScript:void(0);" (click)="GoToShopCollection(subCat.catCode, subCat.subCatCode);">
                                <h6 style="width: 100% !important;" title="{{subCat?.name}}">{{ (subCat?.name).substr(0, 30) | titlecase }}...</h6>
                            </a>
                        </div>
                </div> -->
      </ng-template>
      <!-- <ng-template carouselSlide>
                <div>
                     <a href="javaScript:void(0);" (click)="GoToShopCollection(subCat.catCode, subCat.subCatCode);">
                            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : SubCategoryImage+subCat.imagePath" class="img-fluid w-auto" alt="" />
                        </a>
                        <div class="media-body align-self-center" >
                         
                            <a href="javaScript:void(0);"  (click)="GoToShopCollection(subCat.catCode, subCat.subCatCode);">
                                <h6 style="width: 100% !important;" title="{{subCat.name}}">{{ (subCat?.name).substr(0, 30) | titlecase }}...</h6>
                             </a>
                        </div>
                </div>
            </ng-template> -->
    </ng-container>
  </owl-carousel-o>

</div>

<!-- .block-sub-category-carousel / end -->
