<header class="fixed-top">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="header-contact">
                        <ul>
                            <li>MAYANK GUPTA (Proprietor)</li>
                        </ul>
                        <ul>
                            <!-- <li>ALI BABA CHAIR</li> -->

                            <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: +91-9999163773</li>
                        </ul>

                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="search-container">
                        <input type="text" class="searchtxt" placeholder="Search.." name="search"
                            [(ngModel)]="searchQuery">
                        <a class="searchbtn"
                            href='/shop/collection/left/sidebar?category=HandTools&searchQuery={{searchQuery}}'>
                            <i class="fa fa-search"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-2 text-right">
                    <ul class="header-dropdown">
                        <!-- <li class="compare">
                            <a [routerLink]="['/shop/compare']">
                                <i class="fa fa-random" aria-hidden="true"></i>Compare
                                <span *ngIf="CompareCount>0" class="cart_qty_cls"
                                    style="right:auto">{{CompareCount}}</span>
                            </a> &nbsp;
                        </li> -->
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']">
                                <i class="fa fa-heart" aria-hidden="true"></i>Wishlist
                                <span *ngIf="WishListCount>0" class="cart_qty_cls"
                                    style="right:auto">{{WishListCount}}</span>
                            </a> &nbsp;
                        </li>
                    </ul>
                </div>
                <div class="col-lg-2 text-right">
                    <span class="Logininfo"
                        *ngIf="LoggedInUser==[] || LoggedInUser==undefined || LoggedInUser?.length==0">
                        <a (click)="Login()" class="btn btn-solid btn-solid-small"><i class="fa fa-user"
                                aria-hidden="true"></i>Login</a></span>
                    <ul class="header-dropdown"
                        *ngIf="LoggedInUser!=[] && LoggedInUser!=undefined && LoggedInUser?.length>0">
                        <!--<li class="compare">
                            <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a> &nbsp;
                        </li>
                        <li class="mobile-wishlist">
                         <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
                            &nbsp;
                        </li> -->
                        <!-- <li class="onhover-dropdown mobile-account" *ngIf="LoggedInUser==[] || LoggedInUser==undefined || LoggedInUser?.length==0">
                            <span class="Logininfo"><a (click)="Login()" class="btn btn-solid btn-solid-small"><i
                                        class="fa fa-user" aria-hidden="true"></i>Login</a></span>
                        </li> -->
                        <li class="onhover-dropdown mobile-account">
                            <i class="fa fa-user" aria-hidden="true"></i> {{LoggedInUser[0].name}}
                            <ul class="onhover-show-div">
                                <li>
                                    <a data-lng="en" [routerLink]="['/pages/dashboard']">
                                        My Account
                                    </a>
                                </li>
                                <li>
                                    <a data-lng="en" [routerLink]="['/pages/dashboard/myorder']">
                                        My Order
                                    </a>
                                </li>
                                <li>
                                    <a data-lng="en" [routerLink]="['/pages/dashboard/mypurchaseorder']">
                                        My Purchase Order
                                    </a>
                                </li>
                                <li>
                                    <a data-lng="es" (click)="Logout()">
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="main-menu">
            <div class="menu-left">
                <!-- <app-left-menu></app-left-menu> -->
                <div class="brand-logo">
                    <a routerLink="/home/form" id="headerlogo">
                        <img [src]="themeLogo" class="img-fluid logo-size-chair" alt="logo" width="50px">
                        <!-- <h3>Toolex Mart</h3> -->
                    </a>

                </div>
            </div>
            <div class="menu-right pull-right mt-1">
                <div>
                    <app-menu></app-menu>
                </div>
                <div>
                    <app-settings></app-settings>
                </div>
            </div>
        </div>
    </div>
</header>