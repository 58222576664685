<!--footer section -->
<footer [class]="class">
    <div class="light-layout d-none" *ngIf="newsletter">
        <div class="container">
            <section class="small-section border-section border-top-0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="subscribe">
                            <div>
                                <h4>KNOW IT ALL FIRST!</h4>
                                <p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <form class="form-inline subscribe-form"
                            action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
                            target="_blank" method="post">
                            <div class="form-group mx-sm-3">
                                <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
                                    placeholder="Enter your email">
                            </div>
                            <button type="submit" class="btn btn-solid">subscribe</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <section class="mt-5 footer-dark py-5">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="sub-title">
                            <div class="footer-title">
                                <!-- <img [src]="themeLogo" alt="logo"> -->
                                <h4>Welcome to Toolexmart</h4>
                            </div>
                            <p style="text-align: justify;">Toolexmart is an On Line and Offline Registered Platform
                                allowing intended Buyers towards all the industrial inputs to Shop Online as well. You
                                can visit us
                                offline at our office located at : Bldg. No. 4143, GF Shahtara Street
                                Ajmeri Gate, Delhi 110006</p>
                            <p>
                                Mon-Sat 08:00am - 07:00pm
                            </p>
                        </div>
                        <br>
                        <div style="color:white;;">FOLLOW US </div>
                        <div class="footer-social">

                            <ul>
                                <li>

                                    <a href="#" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
										<a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
									</li> -->
                                <li>
                                    <a href="#" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://web.whatsapp.com/send?text=9999163773" target="_blank"><i
                                            class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
										<a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
									</li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Why Us</h4>
                        </div>
                        <div class="footer-contant uppercase">
                            <ul>
                                <!-- <li><a href="javascript:void(0)">SHIPPING & RETURN</a></li> -->
                                <li>SECURE SHOPPING </li>
                                <li>ALL PAYMENT MODE</li>
                                <li>LATEST TRENDS</li>
                                <li>QUALITY GUARANTEED</li>
                                <li>TRANSPARENT-DEALING</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>USEFUL LINKS</h4>
                        </div>
                        <div class="footer-contant uppercase">
                            <ul>
                                <li><a [routerLink]="['/home/form']">Home</a></li>
                                <li><a [routerLink]="['/pages/aboutus']">About Us</a></li>
                                <li><a [routerLink]="['/pages/privacypolicy']">Privacy Policy</a></li>
                                <!-- <li><a [routerLink]="['/pages/returnpolicy']">Return Policy</a></li> -->
                                <!-- <li><a href="javascript:void(0)">Terms & Conditions</a></li> -->
                                <li><a [routerLink]="['/pages/contact']">Contact Us</a></li>
                                <!-- <li><a href="javascript:void(0)">Latest News</a></li>
                                <li><a href="javascript:void(0)">Our Sitemap</a></li> -->
                                <li><a [routerLink]="['/pages/Download']">Download brochure</a></li>
                                <li><a [routerLink]="['/shop/product-categories']"><b>Product categories</b></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>store information</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li><i class="fa fa-map-marker"></i> Bldg. No. 4143, GF Shahtara Street Ajmeri Gate,
                                    Delhi 110006 (India)</li>
                                <li><i class="fa fa-phone"></i>Call Us: +91-9999163773</li>
                                <li><i class="fa fa-envelope-o"></i>Email Us: <a>sales@Toolexmart.Com</a>
                                </li>
                                <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div style="margin-bottom: 10px;">
                <img src="assets/images/brands/bosch.png" alt="bosch" class="img-brand">
                <img src="assets/images/brands/dasqua.jfif" alt="dasqua" class="img-brand">
                <img src="assets/images/brands/dow.jfif" alt="dow" class="img-brand">
                <img src="assets/images/brands/cumi.png" alt="cumi" class="img-brand">
                <img src="assets/images/brands/fluke.png" alt="fluke" class="img-brand">
                <img src="assets/images/brands/htc.png" alt="htc" class="img-brand">
                <img src="assets/images/brands/IT.png" alt="IT" class="img-brand">
                <img src="assets/images/brands/mccoy.jfif" alt="mccoy" class="img-brand">
                <img src="assets/images/brands/meeco.png" alt="meeco" class="img-brand">
                <img src="assets/images/brands/norton.png" alt="norton" class="img-brand">
                <img src="assets/images/brands/pelican.png" alt="pelican" class="img-brand">
                <img src="assets/images/brands/taparia.png" alt="taparia" class="img-brand">
                <img src="assets/images/brands/totem.png" alt="totem" class="img-brand">
                <img src="assets/images/brands/wiha.png" alt="wiha" class="img-brand">
            </div>
        </div>


    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Copyright E-Com
                            Internet Private Limited, All rights Reserved.</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">

                    <div class="payment-card-bottom">
                        <b>We Accept</b>
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <!-- <a><img src="assets/images/icon/paypal.png" alt=""></a> -->
                                <a><img src="assets/images/icon/DinersClubInternational.jpg" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <!-- <a><img src="assets/images/icon/discover.png" alt=""></a> -->
                                <a><img src="assets/images/icon/eZeClick.jpg" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/RuPay.jpg" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->