import { Component, OnInit, Input } from "@angular/core";

import { NewProductSlider, NewSubCategorySlider } from "../../../data/slider";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { SubCategoryService } from "src/app/Service/Subcategory.service";

@Component({
  selector: "app-block-sub-category",
  templateUrl: "./block-sub-category.component.html",
  styleUrls: ["./block-sub-category.component.scss"],
})
export class BlockSubCategoryComponent implements OnInit {
  public SubCategoryImage = environment.SubCategoryImage;
  @Input() categoryID: string;
  @Input() title: string = "New Category"; // Default
  @Input() lstSubcategory: any[]
  public ImageSrc: string;

  public NewProductSliderConfig: any = NewSubCategorySlider; // NewProductSlider;

  constructor(
    private router: Router,
    private _subCategoryService: SubCategoryService
  ) { }

  ngOnInit(): void {
    // this.GetSubcategoryByCatId();
  }

  GetSubcategoryByCatId() {
    let catObj = {
      Active: true,
      CategoryID: Number(this.categoryID ? this.categoryID : 0),
    };
    this._subCategoryService
      .GetSubcategoryByCatId(catObj)
      .subscribe((subcategory) => {
        // this.lstSubcategory = subcategory;
      });
  }


  GoToShopCollection(url: string) {
    this.router.navigateByUrl(url);
  }
  // GoToShopCollection(catCode: string, subCatCode: string) {
  //   this.router.navigateByUrl(
  //     "/shop/collection/left/sidebar/?category=" +
  //       catCode +
  //       "&subcategory=" +
  //       subCatCode
  //   );
  // }
}
