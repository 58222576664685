<!-- <app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb> -->
<!-- section start -->
<div class="login-page container-fluid">
    <div class="row">
        <div class="col-lg-4 login-left-bg theme-card">
            <div class="login-left">
                <h3>Login</h3>
                <p style="font-weight: 900;padding-bottom: 10px;">Get access to your Orders and shopping-cart</p>
                <img src="assets/images/icon/shopping-cart.svg" alt="ALI BABA CHAIR" class="img-fluid">
            </div>
        </div>
        <div class="col-lg-8 login-right-bg theme-card">
            <form class="theme-form" [formGroup]="LoginForm">
                <div class="form-group">
                    <label for="email" style="font-weight: 900;padding-bottom: 10px;">Mobile number <span
                            style="color: red;">*</span></label>
                    <input type="text" class="form-control" id="email" formControlName="LoginId" maxlength="10"
                        [ngClass]="{ 'is-invalid': submitted && f.LoginId.errors }"
                        placeholder="Enter Registered Mobile Number" autocomplete="off">
                    <div *ngIf="submitted && f.LoginId.errors" class="Customerror">
                        <div>Please enter valid Mobile number</div>
                    </div>
                </div>
                <div class="form-group">
                    <label style="font-weight: 900;padding-bottom: 10px;" for="review">Password <span
                            style="color: red;">*</span></label>
                    <div class="input-group">
                        <input type="{{inputType}}" class="form-control" id="review" formControlName="password"
                            [ngClass]="{ 'is-invalid': f.password.errors }" maxlength="20" placeholder="Enter password">
                        <!-- <div class="input-group-append frm-icon" (click)="hideShowPassword()">
                            <span class="input-group-text">
                                <i class="{{class}}"></i>
                            </span>
                        </div> -->
                    </div>
                    <div *ngIf="submitted && f.password.errors" class="Customerror">
                        <div>Please enter Password
                        </div>
                    </div>
                    <div class="form-group text-center mt-5">
                        <a data-lng="en" href='pages/forget/password'>
                            Forgot Password?
                        </a>
                    </div>
                </div>
                <button [ngClass]="{'btn-loading': loginStart}" class="btn btn-solid w-100"
                    (click)="Login()">Login</button>

            </form>
            <div class="authentication-right text-center mt-5">
                <a data-lng="en" href='/pages/register'>
                    New to toolexmart? Create an Account
                </a>
                <!-- <p> <button class="btn btn-link" (click)="Register();">New to Alibaba
                        Chair? Create an Account</button></p> -->
            </div>
        </div>
    </div>
    <a class="close" (click)="close()"><img src="assets/images/icon/close.svg" alt="Close"></a>
</div>
<!--Section ends-->