<div class="icon-nav">
    <ul>
        <li class="onhover-div mobile-search search-widgets" id="search-widgets">
            <div style="display: none;">
                <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                <i class="ti-search"></i>
            </div>
        </li>
        <!-- <li class="onhover-div mobile-setting">
            <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
                <i class="ti-settings"></i></div>
            <div class="show-div setting">
                <h6>language</h6>
                <ul>
                    <li *ngFor="let language of languages">
                        <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
                    </li>
                </ul>
                <h6>currency</h6>
                <ul class="list-inline">
                    <li *ngFor="let currency of currencies">
                        <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
                    </li>
                </ul>
            </div>
        </li> -->
        <li class="onhover-div mobile-cart">
            <div>
                <a [routerLink]="['/shop/purchaseorder']">
                    <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
                    <i class="ti-shopping-cart"></i>
                </a>
            </div>
            <span class="cart_qty_cls">{{ productSizeColor?.length }}</span>
            <!-- <ul class="show-div shopping-cart" *ngIf='!productSizeColor.length'>
                <h5>Your cart is currently empty.</h5>
            </ul> -->
            <ul class="show-div shopping-cart" *ngIf='productSizeColor?.length>0'>
                <li *ngFor="let product of productSizeColor">
                    <div class="media">
                        <!-- [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" -->
                        <a>
                            <img class="mr-3" *ngIf="product?.setNo>0"
                                [src]="ProductImage+product?.productId+'/productSetImage/'+product?.setNo+'/'+product?.productImg[0]">
                            <!-- <img class="mr-3"  *ngIf="product?.setNo==0" [src]="ProductImage+product?.productId+'/productColorImage/'+product?.productSizeColorId+'/'+product?.productImg[0]"> -->
                            <img class="mr-3" *ngIf="product?.setNo==0"
                                [src]="ProductImage+product?.productId+'/frontImage/'+product?.frontImage">

                            <!-- <img class="mr-3" [src]="ProductImage+product?.productId+'/productColorImage/'+product?.productSizeColorId+'/'+product?.productImg[0]"> -->
                            <!-- [alt]="product?.images[0].alt" -->
                        </a>
                        <div class="media-body">
                            <!-- [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" -->
                            <a>
                                <h6>{{ (product?.productName).split('|')[0] }}</h6>
                            </a>
                            <h6>
                                Quantity: {{ product?.quantity }} 
                                <!-- {{ product?.quantity }} x
                                {{ (product.accessoryPrice+ product?.salePrice) | currency:productService?.Currency.currency:'symbol' }} -->
                            </h6>
                        </div>
                    </div>
                    <div class="close-circle" (click)="removePurchaseCartItem(product)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                </li>
                <!-- <li>
                    <div class="total">
                        <h5>subtotal : <span>{{ getTotal() | currency:productService?.Currency.currency:'symbol' }}</span>
                        </h5> 
                    </div>
                </li> -->
                <li>
                    <div class="buttons">
                        <!-- <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a> -->
                        
                        <a [routerLink]="['/shop/purchaseorder']" class="view-cart">view purchase cart</a>
                        <!-- <a class="checkout" [routerLink]="['/shop/checkout']">checkout</a> -->
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>