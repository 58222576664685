<div class="invoice-container">
    <h4 class="text-center mb-4">
        Order
    </h4>
    <div class="invoice box-padding">
        <div class="addresses">
            <div class="company-details bdr-bottom p10">
                <div class="print-logo mr-3">
<<<<<<< HEAD
                    <img src="/assets/images/logo1.png" alt="Vikram Creation" />
                </div>
                <div>
                    <h5 style="font-weight: 700;">Vikram Creation Pvt Ltd</h5>
=======
                    <img src="/assets/images/logo1.png" alt="ALI BABA CHAIR" />
                </div>
                <div>
                    <h5 style="font-weight: 700;">ALI BABA CHAIR Pvt Ltd</h5>
>>>>>>> c720fc4d576077bb12b97896376414d3f1940bd3
                    <div class="address small">
                        2406, Hardhyan Singh Road,Karol Bagh<br> DELHI-110005 PH: 9810537095/9811144961
                    </div>
                    <div class="tel-number">
                        <span>Tel:</span> 91-11-47330365
                    </div>
                    <div class="gst-number">
                        <span>GSTIN/UIN:</span> 07AABCV8404F1ZG
                    </div>
                    <div>
                        State Name: Delhi, Code: 07
                    </div>
                    <div class="cin-number">
                        <span>CIN:</span> U18101DL2004PTC125737
                    </div>
                    <div class="c-email small">
<<<<<<< HEAD
                        <span>E-Mail:</span> vikramcreation@gmail.com
=======
                        <span>E-Mail:</span> alibabachair@gmail.com
>>>>>>> c720fc4d576077bb12b97896376414d3f1940bd3
                    </div>
                </div>
            </div>
            <div class="consignee bdr-bottom p10">
                <div class="small">Consignee</div>
                <h5 style="font-weight: 700;">{{ orderDetails[0].listUsers[0].name }}</h5>
                <div class="consignee-address">
                    <span>{{ orderDetails[0].listUsers[0].address1 }}, {{orderDetails[0].listUsers[0].city}} </span>
                    <span>{{ orderDetails[0].listUsers[0].state }}, </span>
                    <span>{{ orderDetails[0].listUsers[0].country }} - {{ orderDetails[0].listUsers[0].zipCode }}</span>
                </div>
                <div class="consignee-mobile">
                    <span>Mobile: {{ orderDetails[0].listUsers[0].phone }}</span>
                </div>
                <div class="consignee-mobile">
                    <span>Email : {{ orderDetails[0].listUsers[0].email }}</span>
                </div>
                <div class="consignee-mobile">
                    <span>GSTIN/UIN : </span><span>{{ orderDetails[0].listUsers[0].gstNo }}</span>
                </div>
                <div class="consignee-mobile">
                    <span>PAN No : </span><span>{{ orderDetails[0].listUsers[0].panNo }}</span>
                </div>
                <div class="consignee-state">
                    <span>State Name : </span><span>{{ orderDetails[0].listUsers[0].state }}</span>
                </div>
            </div>
            <div class="Byer p10">
                <div class="small">Buyer (if other than consignee)</div>
                <h5>R.S. ENTERPRISES</h5>
                <div class="byer-address">
                    <span>100-B MODEL TOWN, </span><span>PEHOWA, </span><span>HARYANA</span>
                </div>
                <div class="byer-mobile">
                    <span>Mobile: </span>
                </div>
                <div class="byer-mobile">
                    <span>Email : </span>
                </div>
                <div class="byer-mobile">
                    <span>GSTIN/UIN : </span><span>06CXGPG9693E2Z4</span>
                </div>
                <div class="consignee-mobile">
                    <span>PAN No : </span><span>CFDFDF343434</span>
                </div>
                <div class="byer-state">
                    <span>GSTIN/UIN : </span><span>Haryana, Code : 06</span>
                </div>
            </div>
        </div>
        <div class="invoice-summary">
            <table class="table">
                <tr>
                    <td class="">
                        <span>Invoice No.</span>
                        <h5 style="font-weight: 700;"></h5>
                    </td>
                    <td class="">
                        <span>Dated</span>
                        <h5 style="font-weight: 700;">{{ orderDetails[0].orderDate | date : 'dd-MMM-yyyy'}}</h5>
                    </td>
                </tr>
                <tr>
                    <td class="">
                        <span>Delivery Note</span>
                        <h5></h5>
                    </td>
                    <td class="">
                        <span>Mode/Terms of Payment</span>
                        <h5></h5>
                    </td>
                </tr>
                <tr>
                    <td class="">
                        <span>Supplier’s Ref.</span>
                        <h5 style="font-weight: 700;">00407</h5>
                    </td>
                    <td class="">
                        <span>Other Reference(s)</span>
                        <h5>00407</h5>
                    </td>
                </tr>
                <tr>
                    <td class="">
                        <span>Buyer’s Order No</span>
                        <h5 style="font-weight: 700;">
                            {{ orderDetails[0].orderNumber }}
                        </h5>
                    </td>
                    <td class="">
                        <span>Dated</span>
                        <h5></h5>
                    </td>
                </tr>
                <tr>
                    <td class="">
                        <span>Despatch Document No.</span>
                        <h5></h5>
                    </td>
                    <td class="">
                        <span>Delivery Note Date</span>
                        <h5></h5>
                    </td>
                </tr>
                <tr>
                    <td class="">
                        <span>Despatched Through</span>
                        <h5></h5>
                    </td>
                    <td class="">
                        <span>Destination</span>
                        <h5 style="font-weight: 700;">Consignee</h5>
                    </td>
                </tr>
            </table>
            <div class="delivery p10">
                <div class="small">Delivery Address</div>
                <h5 style="font-weight: 700;">{{ orderDetails[0].fName }} {{ orderDetails[0].lName }}</h5>
                <div class="delivery-address">
                    <span>{{ orderDetails[0].address }}, {{orderDetails[0].city}} </span>
                    <span>{{ orderDetails[0].state }}, </span>
                    <span>{{ orderDetails[0].country }} - {{ orderDetails[0].zipCode }}</span>
                </div>
                <div class="delivery-mobile">
                    <span>Mobile: {{ orderDetails[0].phone }}</span>
                </div>
                <div class="delivery-mobile">
                    <span>Email : {{ orderDetails[0].emailId }}</span>
                </div>
                <div class="delivery-mobile">
                    <span>GSTIN/UIN : </span><span>{{ orderDetails[0].listUsers[0].gstNo }}</span>
                </div>
                <div class="consignee-mobile">
                    <span>PAN No : </span><span>{{ orderDetails[0].listUsers[0].panNo }}</span>
                </div>
                <div class="delivery-state">
                    <span>State Name : </span><span>{{ orderDetails[0].state }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="box-padding">
        <table class="table product-table table-bdr">
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>Description of Goods</th>
                    <th>HSN/SAC</th>
                    <th>Qty</th>
                    <th>Unit</th>
                    <th>Rate Per Pc.</th>
                    <th>Disc %</th>
                    <th>Discounted Rate</th>
                    <th *ngIf="orderDetails[0].orderDetails?.additionalDiscount>0">Add. Discount (%)</th>
                    <th *ngIf="orderDetails[0].orderDetails?.additionalDiscount>0">Add. Discount Amount</th>
                    <th>Amount (Without GST)</th>
                    <th>GST Rate in %</th>
                    <th>IGST</th>
                    <th class="text-right">Grand Total (Amount)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let product of orderDetails[0].orderDetails; let i=index;">
                    <td>{{i+1}}.</td>
                    <td>
                        <b>{{ product?.productName }}</b>
                    </td>
                    <td class="text-right">
                        {{ product?.hsnCode }}
                    </td>
                    <td class="text-right"><b>{{ product?.quantity }}</b></td>
                    <td>PCS</td>
                    <td class="text-right">{{ product?.price | currency:productService?.Currency.currency:'symbol'}}</td>
                    <td>{{ product?.discount }}</td>
                    <td class="text-right">{{ (product?.salePrice) | currency:productService?.Currency.currency:'symbol'}}</td>
                    <td class="text-right" *ngIf="orderDetails[0].orderDetails?.additionalDiscount>0">{{ product?.additionalDiscount}}</td>
                    <td class="text-right" *ngIf="orderDetails[0].orderDetails?.additionalDiscount>0">{{ product?.additionalDiscountAmount | currency:productService?.Currency.currency:'symbol'}}</td>

                    <td class="text-right">
                        <b>
                            {{ (product?.salePrice*product?.quantity)-product?.additionalDiscountAmount | currency:productService?.Currency.currency:'symbol' }}
                        </b>
                    </td>
                    <td class="text-right">{{ product?.gstRate}}</td>
                    <td class="text-right">{{ product?.gstAmount | currency:productService?.Currency.currency:'symbol'}}</td>
                    <td class="text-right">
                        <b>
                            {{ (product.salePrice*product?.quantity-product?.additionalDiscountAmount+product?.gstAmount) | currency:productService?.Currency.currency:'symbol' }}
                        </b>
                    </td>
                </tr>
                <!-- <tr>
                    <td></td>
                    <td>
                        <div class="text-right mt-4 mb-4">
                            <b>IGST OUTPUT-5%</b><br>
                            <div class="round-off"><span>Less: </span><span><b>ROUND OFF</b></span></div>
                        </div>
                    </td>
                    <td class="text-right"></td>
                    <td class="text-right"><b></b></td>
                    <td class="text-right"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">

                        <div class="mt-4 mb-4">
                            <h5><b>735.25</b></h5>
                            <div><b>(-)0.25</b></div>
                        </div>
                    </td>
                </tr> -->
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td class="text-right">Total</td>
                    <td></td>
                    <td class="text-right"><b>{{getQty()}} pcs</b></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td *ngIf="orderDetails[0].orderDetails?.additionalDiscount>0"></td>
                    <td *ngIf="orderDetails[0].orderDetails?.additionalDiscount>0"></td>
                    <td></td>
                    <td>
                        <b>{{getTotalAmountWithOutGST() | currency:productService?.Currency.currency:'symbol'}}</b>
                    </td>
                    <td></td>
                    <td>
                        <b>{{getTotalGSTAmount() | currency:productService?.Currency.currency:'symbol'}}</b>
                    </td>
                    <td>
                        <h5 class="text-right"><b>{{ getTotal() | currency:productService?.Currency.currency:'symbol' }}</b></h5>
                    </td>
                </tr>
            </tfoot>
        </table>
        <div class="ammount-word">
            <div class="left">
                Amount Chargeable (in words)
                <h5>Indian Rupees Fifteen Thousand Four Hundred Forty Only</h5>
            </div>
            <div class="right text-right">
                E. & O.E
            </div>
        </div>

        <table class="table mt-4 table-bdr tax-rate">
            <thead>
                <tr>
                    <th class="text-left" style="width:50%">HSN/SAC</th>
                    <th>Taxable Value</th>
                    <th colspan="2" class="p-lr-0">
                        <div class="bdr-bottom p10 text-center">Integrated Tax</div>
                        <div class="subheading">
                            <span class="left p10 ">Rate</span>
                            <span class="right p10 ">Ammount</span>
                        </div>
                    </th>
                    <th>Total Tax Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">6104</td>
                    <td>14,705.00</td>
                    <td>5%</td>
                    <td>735.25</td>
                    <td>735.25</td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="tax-total">
                    <td>Total</td>
                    <td>14,705.00</td>
                    <td></td>
                    <td>735.25</td>
                    <td>735.25</td>
                </tr>
            </tfoot>
        </table>

        <div>
            Tax Amount (in words) :<b> Indian Rupees Seven Hundred Thirty Five and Twenty Five paise Only</b>
        </div>

        <div class="declaration mt-5">
            <div class="left">
                Company’s PAN : AABCV8404F
                <div class="small mt-3">
                    Declaration
                    <p>
                        We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.
                    </p>
                </div>
            </div>
            <div class="right text-right">
<<<<<<< HEAD
                <b>for VIKRAM CREATIONS PVT LTD</b>
=======
                <b>for ALI BABA CHAIR</b>
>>>>>>> c720fc4d576077bb12b97896376414d3f1940bd3

                <p class="mt-5">
                    Authorised Signatory
                </p>
            </div>
        </div>
        <p class="text-center">
            This is a Computer Generated Invoice
        </p>
    </div>
</div>