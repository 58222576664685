<ngx-loading-bar></ngx-loading-bar>
<ngx-spinner [fullScreen]="true" type="ball-scale" size="large">
    <p style="color: white"> </p>
</ngx-spinner>
<!-- 
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-fade" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
</ngx-spinner> 

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

-->

<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<!-- <app-layout-box></app-layout-box> -->