import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  private BASE_API_URL = environment.BASE_API_URL;
  private _controllerName: string = "PurchaseOrder/";
  private _url: string = this.BASE_API_URL + this._controllerName;
  private _methodName: string = "";
  private _param: {};

  constructor(private _http: HttpClient) { }

  AddToPurchaseOrder(_Obj: any[]): Observable<any[]> {
    this._methodName = "AddToPurchaseOrder";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }

  AddToPurchaseOrderAccessory(_Obj: any[]): Observable<any[]> {
    this._methodName = "AddToPurchaseOrderAccessory";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }

  DelPurchaseOrderById(_Obj: any): Observable<any[]> {
    this._methodName = "DelPurchaseOrderById";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }

  GetPurchaseOrderById(_Obj: any): Observable<any[]> {
    this._methodName = "GetPurchaseOrderById";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }
  
  UpdateToPurchaseOrder(_Obj: any[]): Observable<any[]> {
    this._methodName = "UpdateToPurchaseOrder";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }

  GetPurchaseOrderProcessedById(_Obj: any): Observable<any[]> {
    this._methodName = "GetPurchaseOrderProcessedById";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }

  
  AddToPurchaseCart(_Obj: any[]): Observable<any[]> {
    this._methodName = "AddToPurchaseCart";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }
  
  UpdateToPurchaseCart(_Obj: any[]): Observable<any[]> {
    this._methodName = "UpdateToPurchaseCart";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }
  DelPurchaseCartById(_Obj: any): Observable<any[]> {
    this._methodName = "DelPurchaseCartById";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }

  GetPurchaseCartById(_Obj: any): Observable<any[]> {
    this._methodName = "GetPurchaseCartById";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }
  
  
  PurchaseOrder(_Obj: any): Observable<any[]> {
    this._methodName = "PurchaseOrder";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }
  GetAllPurchaseOrderByUserId(): Observable<any> {
    this._methodName = "GetAllPurchaseOrderByUserId";
    this._param = {};
    return this._http.post<any>(
      this._url + this._methodName, this._param
    );
  }

  GetProductSizeColorByProductGuid(_Obj: any): Observable<any[]> {
    this._methodName = "GetProductSizeColorByProductGuid";
    this._param = _Obj;
    return this._http.post<any[]>(
      this._url + this._methodName, this._param
    );
  }
  
}
